const initialState = {
  cognitoUser: null,
  loading: {},
  saved: {},
  error: '',
  success: '',
  localUser: {},
  licenses: [],
  allLicenses: [],
  allUsers: [],
  friendlyId: '',
  file: {},
  showToc: true,
  issuingLicense: false,
  issuedLicense: {}
};

export default function reducers (state = initialState, action) {
  switch (action.type) {
    case 'SAVE_COGNITO_USER':
      return {
        ...state, cognitoUser: action.cognitoUser
      };
    case 'SET_LOGOUT_EXPLICIT':
      return {
        ...state,
        logoutExplicit: action.payload
      };
    case 'SET_SAVED':
      return {
        ...state, saved: { [action.key]: true }
      };
    case 'SET_ERROR':
      return {
        ...state, error: action.error
      };
    case 'SET_SUCCESS':
      return {
        ...state, success: action.success
      };
    case 'SET_FILE':
      return {
        ...state, file: action.file
      };
    case 'TOC_NOTICE':
      return {
        ...state, showToc: false
      };
    case 'CLEAR_LOADING':
      return {
        ...state, loading: { }
      };
    case 'SET_LOADING':
      return {
        ...state, loading: { ...state.loading, [action.key]: action.loading }
      };
    case 'SAVE_AUTH0_USER':
      return {
        ...state, auth0User: action.user
      };
    case 'SAVE_LOCAL_USER':
      return {
        ...state, localUser: action.user
      };
    case 'SAVE_LICENSES':
      return {
        ...state, licenses: action.licenses
      };
    case 'SAVE_ALL_LICENSES':
      return {
        ...state, allLicenses: action.allLicenses
      };
    case 'SAVE_ALL_USERS':
      return {
        ...state, allUsers: action.allUsers
      };
    case 'SAVE_LABEL':
      return {
        ...state, friendlyId: action.friendlyId
      };
    case 'ISSUING_LICENSE':
      return {
        ...state, issuingLicense: true
      };
    case 'LICENSE_ISSUED':
      return {
        ...state,
        issuingLicense: false,
        issuedLicense: action.result
      };
    case 'CLEAR_LICENSE':
      return {
        ...state,
        issuedLicense: {}
      };
    case 'SET_UPVOTY_TOKEN':
      return {
        ...state,
        // Store only the token string here, not the entire action
        upvotyToken: action.upvotyToken
      };
    case 'SAVE_LICENSE_TYPE_STATS':
      return {
        ...state,
        licenseTypeStats: action.licenseTypeStats
      };
    case 'SAVE_USER_STATS':
      return {
        ...state,
        userStats: action.userStats
      };
    case 'SAVE_ACTIVE_PACKAGES':
      return {
        ...state,
        activePackages: action.packages
      };
    case 'FETCH_ACTIVE_PACKAGES_FAILURE':
      return {
        ...state,
        error: action.error
      };

    case 'SAVE_PACKAGE_URL':
      return {
        ...state,
        packageUrlInfo: action.payload
      };
    case 'FETCH_PACKAGE_URL_FAILURE':
      return {
        ...state,
        error: action.error
      };

    case 'UPDATE_PACKAGE_URL_SUCCESS':
      return {
        ...state,
        success: JSON.stringify(action.payload)
      };
    case 'UPDATE_PACKAGE_URL_FAILURE':
      return {
        ...state,
        error: action.error
      };

    case 'SAVE_PLUGIN_MESSAGE':
      return {
        ...state,
        pluginMessage: action.payload
      };
    case 'FETCH_PLUGIN_MESSAGE_FAILURE':
      return {
        ...state,
        error: action.error
      };

    case 'UPDATE_PLUGIN_MESSAGE_SUCCESS':
      return {
        ...state,
        success: JSON.stringify(action.payload)
      };
    case 'UPDATE_PLUGIN_MESSAGE_FAILURE':
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
