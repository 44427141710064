import apiCall, { makeUrl } from '../../utils/apiCall';
import { getFriendlyName } from '../../utils/licenseUtils';
import { call, put, takeLatest } from 'redux-saga/effects';

import moment from 'moment';
import { push } from 'connected-react-router';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function refreshPage () {
  window.location.reload(false);
}

function safe (sagaFn) {
  return function * (args) {
    try {
      const result = yield call(sagaFn, args);
      return result;
    } catch (error) {
      yield put({ type: 'SET_ERROR', error: (error.response && error.response.data.message) || error.message });
      yield put({ type: 'CLEAR_LOADING' });
    }
  };
}

function * tocNotice (showToc) {
  showToc = false;
  yield put({ type: 'SHOW_TOC', showToc: showToc });
}

function * listLicenses () {
  const listLicenseCall = yield apiCall();
  const result = yield listLicenseCall.get(makeUrl('/license/v2/list?all=1'));
  yield put({ type: 'SAVE_LICENSES', licenses: result.data.licenses });
}

function * downloadLicense ({ licenseId, hardwareId, checkoutTimeframe, callback }) {
  const downloadLicenseCall = yield apiCall();
  const result = yield downloadLicenseCall.post(makeUrl('/license/download256'), { 'license-id': licenseId, 'hardware-id': hardwareId, ttl: checkoutTimeframe });
  yield put({ type: 'SET_FILE', file: result.data });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been generated!' });
  if (callback) {
    yield callback();
  }
}

// function * RquestLicense ({ licenseId }) {
//   const requestLicenseCall = yield apiCall();
//   yield requestLicenseCall.post(makeUrl('/license/request'), { 'license-id': licenseId });
//   // yield put({ type: 'SAVE_LICENSES', licenses: result.data.licenses });
//   yield put({ type: 'SET_SUCCESS', success: 'This request was successful.' });
// }

function * labelLicense ({ licenseId, licenseLabel }) {
  yield put({ type: 'SET_LOADING', key: 'label-license-' + licenseId, loading: true });
  const labelLicenseCall = yield apiCall();
  yield labelLicenseCall.patch(makeUrl('/license/label'), { 'license-id': licenseId, 'license-label': licenseLabel });
  yield put({ type: 'SAVE_LABEL', friendlyId: licenseLabel });
  yield put({ type: 'SET_LOADING', key: 'label-license-' + licenseId, loading: false });
}

function * delegateLicense ({ licenseId, email }) {
  const delegateLicenseCall = yield apiCall();
  yield delegateLicenseCall.patch(makeUrl('/license/delegate'), { 'license-id': licenseId, 'user-email': email });
  if (email === '') {
    yield put({ type: 'SET_SUCCESS', success: 'License has been repossessed.' });
  } else {
    yield put({ type: 'SET_SUCCESS', success: 'This license has been delegated.' });
  }
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * revokeLicense ({ licenseId, hardwareId }) {
  const revokeLicenseCall = yield apiCall();
  yield revokeLicenseCall.post(makeUrl('/license/revoke'), { 'license-id': licenseId, 'hardware-id': hardwareId });
  yield put({ type: 'SET_SUCCESS' });
}

// admin sagas

function * listAllLicenses ({ isActive }) {
  const listAllLicensesCall = yield apiCall();

  if (isActive) {
    const result = yield listAllLicensesCall.get(makeUrl('/admin/v2/listAll?active=1'));
    yield put({ type: 'SAVE_ALL_LICENSES', allLicenses: result.data.licenses });
  } else {
    const result = yield listAllLicensesCall.get(makeUrl('/admin/v2/listAll'));
    yield put({ type: 'SAVE_ALL_LICENSES', allLicenses: result.data.licenses });
  }
}
function * downloadLicenseAll ({ licenseId, hardwareId, checkout, callback }) { // Changed property name
  const downloadLicenseCall = yield apiCall();
  const result = yield downloadLicenseCall.post(makeUrl('/admin/downloadAny'), {
    'license-id': licenseId,
    'hardware-id': hardwareId,
    ttl: checkout // Use 'checkout' directly
  });
  yield put({ type: 'SET_FILE', file: result.data });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been generated!' });
  if (callback) {
    yield callback();
  }
}

function * labelLicenseAll ({ licenseId, licenseLabel }) {
  const labelLicenseCall = yield apiCall();
  yield labelLicenseCall.patch(makeUrl('/admin/labelAny'), { 'license-id': licenseId, 'license-label': licenseLabel });
  yield put({ type: 'SAVE_LABEL', friendlyId: licenseLabel });
}

function * listAllUsers () {
  const listAllUsersCall = yield apiCall();
  const result = yield listAllUsersCall.get(makeUrl('/admin/listAllUsers'));
  yield put({ type: 'SAVE_ALL_USERS', allUsers: result.data.users });
}

function * issueNewLicense ({ email, name, expiration, ttl, licenseType, isactive }) {
  const issueNewLicense = yield apiCall();
  const { data: { message: result } } = yield issueNewLicense.post(makeUrl('/admin/create'), {
    email,
    name,
    expiration: moment(expiration).utc().format('YYYY-MM-DD hh:mm:ss'),
    ttl,
    type: licenseType,
    isactive
  });

  yield put({ type: 'LICENSE_ISSUED', result });
}

function * editLicense ({ username, name, licenseId, expiration, ttl, licenseType, isactive }) {
  const issueNewLicense = yield apiCall();
  yield issueNewLicense.patch(makeUrl('/admin/update'), {
    name,
    'license-id': licenseId,
    expiration: moment(expiration).format('YYYY-MM-DD'),
    ttl,
    type: licenseType,
    username,
    isactive
  });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been updated.' });
  yield put(push('/all-licenses'));
}

function * delegateLicenseAll ({ licenseId, email }) {
  const delegateLicenseAllCall = yield apiCall();
  yield delegateLicenseAllCall.patch(makeUrl('/admin/delegateAny'), { 'license-id': licenseId, 'user-email': email });
  if (email === '') {
    yield put({ type: 'SET_SUCCESS', success: 'License has been repossessed.' });
  } else {
    yield put({ type: 'SET_SUCCESS', success: 'This license has been delegated.' });
  }
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * revokeLicenseAll ({ licenseId }) {
  const revokeLicenseCall = yield apiCall();
  yield revokeLicenseCall.patch(makeUrl('/admin/revokeAny'), { 'license-id': licenseId });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been revoked.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * promoteToAdmin (username) {
  const promoteToAdminCall = yield apiCall();
  yield promoteToAdminCall.patch(makeUrl('/admin/AddUserToGroup'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been promoted to admin.' });
}

function * demoteToUser (username) {
  const demoteToUserCall = yield apiCall();
  yield demoteToUserCall.patch(makeUrl('/admin/RemoveUserFromGroup'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user is no longer an admin.' });
}

function * forcePasswordChange (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/ResetUserPassword'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This account password has been reset.' });
}

function * resendVerificationEmail (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/ResendSignUp'), username);
  yield put({ type: 'SET_SUCCESS', success: 'A verification email has been resent. Pleaes have the user check their email.' });
}

function * enableUser (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/EnableUser'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been enabled.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * disableUser (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/DisableUser'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been disabled.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * fetchLicenseTypeStats () {
  const licenseTypeStatsCall = yield apiCall();
  const result = yield licenseTypeStatsCall.get(makeUrl('/admin/license-type-stats'));

  // take result object and map the type to a friendly name
  const mappedResult = result.data.map(item => {
    return {
      type: getFriendlyName(item.type),
      count: item.count
    };
  });

  yield put({ type: 'SAVE_LICENSE_TYPE_STATS', licenseTypeStats: mappedResult });
}

function * fetchUserStats () {
  const userStatsCall = yield apiCall();
  const result = yield userStatsCall.get(makeUrl('/admin/user-stats'));
  yield put({ type: 'SAVE_USER_STATS', userStats: result.data });
}

function * openCustomerInStripe (action) {
  const { stripeCustomerId } = action; // Destructure stripeCustomerId from the action
  if (!stripeCustomerId) {
    yield put({ type: 'SET_ERROR', error: 'No customer ID found.' });
    return;
  }
  window.open(`https://manage.stripe.com/customers/${stripeCustomerId}`, '_blank');
}

function * openCustomerInHubspot (action) {
  const { email } = action; // Destructure stripeCustomerId from the action
  console.log(action);
  if (!email) {
    yield put({ type: 'SET_ERROR', error: 'No email found.' });
    return;
  }
  // encode email
  const encodedCustEmail = encodeURIComponent(email);
  window.open(`https://app.hubspot.com/contacts/9001676/objects/0-1/views/all/list?query=${encodedCustEmail}`, '_blank');
}

function * openCustomerPortal (action) {
// send to /api/billing/portal will return json with url
  const billingPortalCall = yield apiCall();
  const result = yield billingPortalCall.get(makeUrl('/billing/portal'));

  // check if data has error
  if (result.data.error) {
  // default to billing portal
    window.open('https://billing.imaginary-labs.com/p/login/eVa8wx7HGeO4gg0cMM?prefilled_email=' + encodeURIComponent(action.email), '_self');
  } else {
    window.open(result.data.url, '_self');
  }
}

/**
 * =================================================
 *  NEW PACKAGE TOOL SAGA FUNCTIONS (NO CONSTANTS)
 * =================================================
 */

function * fetchActivePackages () {
  const request = yield apiCall();
  const response = yield request.get(makeUrl('/admin/get-active-packages'));
  if (response.data) {
    // We inline the 'SAVE_ACTIVE_PACKAGES' string literal
    yield put({ type: 'SAVE_ACTIVE_PACKAGES', packages: response.data });
  } else {
    yield put({ type: 'FETCH_ACTIVE_PACKAGES_FAILURE', error: 'No data returned' });
  }
}

function * fetchPackageUrl (action) {
  const { packageId } = action;
  const request = yield apiCall();
  const response = yield request.get(makeUrl(`/admin/get-package-url/${packageId}`));
  if (response.data) {
    yield put({ type: 'SAVE_PACKAGE_URL', payload: response.data });
  } else {
    yield put({ type: 'FETCH_PACKAGE_URL_FAILURE', error: 'No data returned' });
  }
}

function * updatePackageUrl (action) {
  const { packageId, newPackageUrl } = action;
  const request = yield apiCall();
  const body = { special_package_id: packageId, new_package_url: newPackageUrl };
  const response = yield request.post(makeUrl('/admin/update-package-url'), body);

  if (response.data) {
    yield put({ type: 'UPDATE_PACKAGE_URL_SUCCESS', payload: response.data });
    // Optionally re-fetch the same package
    yield put({ type: 'FETCH_PACKAGE_URL', packageId });
  } else {
    yield put({ type: 'UPDATE_PACKAGE_URL_FAILURE', error: 'No data returned' });
  }
}

function * fetchPluginMessage () {
  const request = yield apiCall();
  const response = yield request.get(makeUrl('/admin/get-plugin-message'));
  if (response.data) {
    yield put({ type: 'SAVE_PLUGIN_MESSAGE', payload: response.data[0] });
  } else {
    yield put({ type: 'FETCH_PLUGIN_MESSAGE_FAILURE', error: 'No data returned' });
  }
}

function * updatePluginMessage (action) {
  const { newVersion, newMessage } = action;
  const request = yield apiCall();
  const body = { currentVersion: newVersion, upgradeMessage: newMessage };
  const response = yield request.post(makeUrl('/admin/update-plugin-message'), body);

  if (response.data) {
    yield put({ type: 'UPDATE_PLUGIN_MESSAGE_SUCCESS', payload: response.data });
    // Optionally re-fetch the plugin message
    yield put({ type: 'FETCH_PLUGIN_MESSAGE' });
  } else {
    yield put({ type: 'UPDATE_PLUGIN_MESSAGE_FAILURE', error: 'No data returned' });
  }
}

function * getUpvotyToken () {
  const upvotyApi = yield call(apiCall, {});
  const response = yield call([upvotyApi, upvotyApi.get], makeUrl('/upvoty/token'));
  yield put({ type: 'SET_UPVOTY_TOKEN', upvotyToken: response.data.token });
  // yield call(delay, 1000);
}

function * sagas () {
  yield takeLatest('TOC_NOTICE', safe(tocNotice));
  yield takeLatest('LIST_LICENSES', safe(listLicenses));
  yield takeLatest('DOWNLOAD_LICENSE', safe(downloadLicense));
  // yield takeLatest('REQUEST_LICENSE', RequestLicense);
  yield takeLatest('UPDATE_LABEL', safe(labelLicense));
  yield takeLatest('DELEGATE_LICENSE', safe(delegateLicense));
  yield takeLatest('REVOKE_LICENSE', safe(revokeLicense));
  yield takeLatest('GET_UPVOTY_TOKEN', safe(getUpvotyToken));
  yield takeLatest('OPEN_CUSTOMER_PORTAL', safe(openCustomerPortal));

  // admin sagas here
  yield takeLatest('LIST_ALL_LICENSES', safe(listAllLicenses));
  yield takeLatest('DOWNLOAD_LICENSE_ALL', safe(downloadLicenseAll));
  yield takeLatest('UPDATE_LABEL_ALL', safe(labelLicenseAll));
  yield takeLatest('LIST_USERS', safe(listAllUsers));
  yield takeLatest('ISSUING_LICENSE', safe(issueNewLicense));
  yield takeLatest('EDIT_LICENSE', safe(editLicense));
  yield takeLatest('DELEGATE_LICENSE_ALL', safe(delegateLicenseAll));
  yield takeLatest('REVOKE_LICENSE_ALL', safe(revokeLicenseAll));

  // admin actions here
  yield takeLatest('PROMOTE_TO_ADMIN', safe(promoteToAdmin));
  yield takeLatest('DEMOTE_TO_USER', safe(demoteToUser));
  yield takeLatest('FORCE_PASSWORD_RESET', safe(forcePasswordChange));
  yield takeLatest('RESEND_VERIFICATION_EMAIL', safe(resendVerificationEmail));
  yield takeLatest('ENABLE_USER', safe(enableUser));
  yield takeLatest('DISABLE_USER', safe(disableUser));
  yield takeLatest('OPEN_CUSTOMER_IN_STRIPE', safe(openCustomerInStripe));
  yield takeLatest('OPEN_CUSTOMER_IN_HUBSPOT', safe(openCustomerInHubspot));
  yield takeLatest('FETCH_LICENSE_TYPE_STATS', safe(fetchLicenseTypeStats));
  yield takeLatest('FETCH_USER_STATS', safe(fetchUserStats));

  // deployments sagas
  yield takeLatest('FETCH_ACTIVE_PACKAGES', safe(fetchActivePackages));
  yield takeLatest('FETCH_PACKAGE_URL', safe(fetchPackageUrl));
  yield takeLatest('UPDATE_PACKAGE_URL', safe(updatePackageUrl));
  yield takeLatest('FETCH_PLUGIN_MESSAGE', safe(fetchPluginMessage));
  yield takeLatest('UPDATE_PLUGIN_MESSAGE', safe(updatePluginMessage));
}

export default sagas;
